import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO/SEOPage';
import Layout from '../components/shared/layout';
import { wrapper,breakpoints } from '../utils/style';
import styled from '@emotion/styled';
import Parallax from "../components/shared/Backgrounds/Parallax";

const Wrapper = styled('div')`
  ${wrapper}
  .info-wrapper {
    margin: 30px auto;
    padding: 60px 30px;;
    text-align: center;
    h1 {
      margin:0 0 30px;
    }
    p {
      margin: 30px 0;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .info-wrapper {
      padding: 30px 15px;
    }
  }
  
`;

const RegSuccessPage = () => (
  <Layout>
    <SEO title='ลงทะเบียนเสร็จสมบูรณ์!'/>
    <Parallax />
    <Wrapper>
      <div className={"info-wrapper"}>
        <h1 className="gradient a-center">ลงทะเบียนเสร็จสมบูรณ์!</h1>
        <p>กรุณาเช็คอีเมลของคุณเพื่อเปิดการใช้งานบัญชีเกมฟรี</p>
        <Link to="/" className="button gradient-border-button">
          <span>กลับไปยังหน้าโฮมเพจ</span>
        </Link>
      </div>
    </Wrapper>
  </Layout>
);

export default RegSuccessPage;
